import { IAppEnvironment } from './environment.model';

export const environment: IAppEnvironment = {
  production: false,
  appSettings: {
    tooltip: {
      showDuration: 0,
      hideDuration: 3000,
    },
    snackbar: {
      duration: 15000,
      shortDuration: 5000,
      longDuration: 30000,
    },
    controls: {
      inputDebounceTime: 500,
      appearance: 'outline',
      floatLabel: 'always',
      dateFormat: {
        parse: {
          dateInput: 'dd/MM/yyyy', // this is how your date will be parsed from Input
        },
        display: {
          dateInput: 'dd/MM/yyyy', // this is how your date will get displayed on the Input
          monthYearLabel: 'MMMM yyyy',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    auth: {
      defaultCountryCode: 'GB',
      isUse2Fa: true,
    },
    locale: {
      date: 'dd/MM/yyyy',
      time: 'H:mm',
      dateTime: 'MM/dd/yyyy, h:mm',
    },
    showInactiveBlock: false
  },
  vendor: {
    company: {
      name: 'IRONWALLET',
      siteAddress: 'https://finflowsoft.com/',
    },
    theme: {
      name: 'IRONWALLET',
      devMode: false,
      autoDarkMode: false,
      isShowDarkModeSwitch: true,
    },
  },
  apiSettings: {
    baseUrl: 'https://iwweb-back.ffsw.dev/',
    baseWebSocketUrl: 'https://iwweb-back.ffsw.dev/',
    versionUrl: 'https://webapp-ironwallet-version.ffsw.dev/'
  },
};
